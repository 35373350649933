.header-wrap {
    max-width: 1680px;
    margin: 0 auto;
}

.header {
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
    align-items: center;
}

.active-base {
    font-family: "Afacad", sans-serif !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
    color: #FFF !important;
}

.active-white {
    font-family: "Afacad", sans-serif !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
    color: #1B1C1D !important;
}

.not-active-base {
    font-family: "Afacad", sans-serif !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    color: #7C8C9D !important;
}

.not-active-white {
    font-family: "Afacad", sans-serif !important;
    color: #7C8C9D !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    color: rgba(255, 255, 255, 0.50) !important;
}

.language-wrap {
    padding: 0 8px;
    margin: 0 1px
}

.theme-toggle {
    font-size: 0;
    text-decoration: none;
    border-radius: 8px;
    background: #FFF;
    cursor: pointer;
}

.theme-toggle:hover {
    background: #F4F6F9;
    transition: background 0.2s linear;
}

.theme-toggle-black {
    text-decoration: none;
    border-radius: 8px;
    background: #000000;
    cursor: pointer;
}

.theme-toggle-black:hover {
    background: rgba(255, 255, 255, 0.15);
}

.theme-toggle-icon {
    font-size: 0;
    width: 32px;
    height: 32px;
}

@media (max-width: 483px) {
    .header {
        flex-wrap: wrap;
    }
}


@media (max-width: 390px) {
    .header {
        padding-right: 0;
    }
}

@media (max-width: 320px) {
    .header :nth-child(2) {margin: auto;}
}

/*@media (max-width: 375px) {*/
/*    .header {*/
/*        padding-right: 20px;*/
/*    }*/
/*}*/
