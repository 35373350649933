.modal-wrapper {
    position: absolute;
    top: 200px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    max-width: 570px;
    width: 100%;
    padding: 32px;
    border-radius: 32px;
    background: #FFF;
    z-index: 10;
}

.modal-black {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.15) 100%), #000;
}

.modal-header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}


.modal-header {
    font-family: 'Afacad', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    color: #1B1C1D;
    font-feature-settings: 'ss02' on, 'liga' off;
}

.modal-header-white {
    color: #FFF;
}

.modal-header-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.modal-descr {
    width: 97%;
    margin: 0 0 20px 0;
    font-family: 'Afacad', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: #7C8C9D;
    font-feature-settings: 'ss02' on, 'liga' off;
}

.modal-descr-white {
    color: rgba(255, 255, 255, 0.70);
}
