@font-face {
    font-family: 'Afacad';
    src: url("../../assets/fonts/Afacad-Regular.ttf");
    font-weight: normal;
    font-display: auto;
}

@font-face {
    font-family: 'Afacad';
    src: url("../../assets/fonts/Afacad-Bold.ttf");
    font-weight: bold;
    font-display: auto;
}

@font-face {
    font-family: 'Afacad';
    src: url("../../assets/fonts/Afacad-Medium.ttf");
    font-weight: 500;
    font-display: auto;
}

@font-face {
    font-family: 'Afacad';
    src: url("../../assets/fonts/Afacad-SemiBold.ttf");
    font-weight: 600;
    font-display: auto;
}

.bold {
    font-family: "Afacad", sans-serif !important;
    font-weight: 400 !important;
}

.blurred {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    pointer-events: auto;
    background: rgba(27, 28, 29, 0.50);
    backdrop-filter: blur(10px);
    transition: backdrop-filter 0.3s ease;
}

.modal-dialog-enter {
    opacity: 0;
}

.modal-dialog-enter-active {
    opacity: 1;
    transition: opacity 200ms;
}

.modal-dialog-exit {
    opacity: 1;
}

.modal-dialog-exit-active {
    opacity: 0;
    transition: opacity 200ms;
}

.buttons-block {
    font-family: "Afacad", sans-serif !important;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 50%;
    margin-left: 1rem;
}

.projects-links-button {
    display: flex;
    align-items: center;
}

.logo {
    cursor: pointer;
    width: 100%;
    max-width: 170px;
}

.hero {
    background-color: #000000;
    position: relative;
    overflow-x: hidden;
}

.hero-white {
    composes: hero;
    background-color: #FFF;
}

.hero-spiral {
    pointer-events: none;
    position: absolute;
    top: -269px;
    right: -151px;
    z-index: 1;
}

.hero-arrow {
    margin-left: 3.75rem;
}

.hero-arrow:hover {
    cursor: pointer;
}

.hero-title-block {
    margin: 177px 0;
}

.hero-title-wrapper {
    margin-right: 17px;
}

.hero-title {
    font-family: "Afacad", sans-serif;
    position: relative;
    margin: 0 0 24px;
    font-size: 102px;
    font-weight: 700;
    color: #FFFFFF;
    z-index: 2;
    font-feature-settings: 'ss02' on, 'liga' off;
}

.hero-black {
    font-family: "Afacad", sans-serif;
    position: relative;
    margin: 0 0 24px;
    font-size: 102px;
    font-weight: 700;
    color: #1B1C1D;
    z-index: 2;
    font-feature-settings: 'ss02' on, 'liga' off;
}

.highlight {
    display: inline-block;
    text-decoration: underline;
    color: #388EFF;
}

.hero-descr {
    margin: 0 0 72px 0;
    font-family: "Afacad", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    font-feature-settings: 'ss02' on, 'liga' off;
    color: #7C8C9D;
}

.hero-descr-white {
    margin: 0 0 72px 0;
    font-family: "Afacad", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    font-feature-settings: 'ss02' on, 'liga' off;
    color: rgba(255, 255, 255, 0.70);
}

.hero-footer {
    display: flex;
    align-items: center;
}

.hero-footer-btn {
    margin-right: 24px;
}

.hero-footer-wrap {
    display: flex;
    align-items: center;
    padding: 10px 24px;
    font-family: "Afacad", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    cursor: pointer;
}

.hero-footer-base {
    color: #1B1C1D;
}

.hero-footer-white {
    color: #FFF;
}

.hero-footer-descr {
    padding-right: 6px;
}

.info-section {
    padding: 64px 0;
    background-color: #FFF;
}

.info-wrapper {
    display: flex;
}

.info-block {
    width: 100%;
    max-width: 100%!important;
    margin-bottom: 20px;
}

.info-section-black {
    background-color: #000000;
}

.info-contact {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 32px;
    border-radius: 24px;
    background: #F4F6F9;
}

.info-contact-black {
    background: rgba(255, 255, 255, 0.08);
}


.info-contact-header {
    margin-bottom: 8px;
    font-family: 'Afacad', sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    color: #1B1C1D;
    font-feature-settings: 'ss02' on, 'liga' off;
}

.info-contact-header-white {
    color: #FFF;
}

.info-contact-descr {
    font-family: 'Afacad', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: #47525D;
    font-feature-settings: 'ss02' on, 'liga' off;
}

.info-contact-descr-white {
    color: rgba(255, 255, 255, 0.70);
}

.projects-block {
    padding: 64px 0;
    background-color: #FFF;
}

.projects-block-dark {
    background-color: #000000;
}

.projects-header {
    margin-bottom: 32px;
    font-family: 'Afacad', sans-serif;
    font-size: 38px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-feature-settings: 'ss02' on, 'liga' off;
    color: #1B1C1D;
}

.projects-header-dark {
    color: #FFF;
}

.green {
    background-color: #BFFDE8;
    grid-area: green;
}

.black {
    position: relative;
    padding-bottom: 9.38rem;
    background-color: #000000;
    color: #FFFFFF;
    grid-area: black;
}

.blue {
    position: relative;
    padding-bottom: 9.38rem;
    background-color: #388EFF;
    color: #FFFFFF;
    grid-area: blue;
}

.lightBlue {
    position: relative;
    background-color: #7FB4FB;
    grid-area: light-blue;
}

.green-spiral-mobile,
.black-spiral-mobile,
.blue-spiral-mobile,
.lightBlue-spiral-mobile {
    display: none;
}

.green .card-description {
    max-width: 42.5rem;
}

.lightBlue .card-description {
    max-width: 41.25rem;
}

.contact-wrapper {
    padding: 64px 0;
    background-color: #FFF;
}

.contact-wrapper-dark {
    padding: 64px 0;
    background-color: #000000;
}

.contact-block {
    display: flex;
    justify-content: space-between;
}

.contact-card-wrapper {
    flex-basis: 48%;
    padding: 32px;
    border-radius: 24px;
    background: url('/src/assets/img/footer_spiral.svg'), linear-gradient(264deg, #388EFF 1.92%, #166EE3 96.1%) center;
    background-size: cover;
}

.contact-card-header {
    margin-bottom: 12px;
    font-family: 'Afacad', sans-serif;
    font-size: 38px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-feature-settings: 'ss02' on, 'liga' off;
    color: #FFF;
}

.contact-card-descr {
    font-family: 'Afacad', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 22px;
    font-style: normal;
    font-feature-settings: 'ss02' on, 'liga' off;
    color: #FFF;
}

.contact-form-wrapper {
    flex-basis: 48%;
}

.language-selector {
    margin-right: 24px;
}

.cookie-wrap {
    position: fixed;
    right: 24px;
    bottom: 24px;
    padding: 20px 24px;
    background-color: #FFF;
    border-radius: 18px;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.15);
    z-index: 10;
    transition: right 0.1s linear;
}

.cookie-wrap-hidden {
    position: fixed;
    right: -440px;
    bottom: 24px;
    padding: 20px 24px;
    background-color: #FFF;
    border-radius: 18px;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.15);
    z-index: 10;
    transition: right 0.1s linear;
}

.header-mobile {
    display: none;
    position: fixed;
    bottom: 0;
}

@media (max-width: 1024px) {
    .content,
    .contact-wrapper {
        padding-left: 1.56rem;
        padding-right: 1.56rem;
    }

    .hero-title {
        font-size: 3.12rem;
        max-width: 701px;
        margin-top: 5.31rem;
    }

    .hero-title-block {
        margin: 100px 0;
    }

    .buttons-block {
        width: 60%;
    }

    .buttons-block button {
        display: none;
    }

    .language-selector {
        margin-right: 3rem;
    }

    .language-selector:first-of-type {
        margin-right: 0;
    }

    .projects-links-button {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
    }

    .hero-footer {
        flex-direction: column;
    }

    .hero-footer-btn {
        margin: 0 0 12px;
        width: 100%;
    }

    .info-section {
        padding: 32px 0;
    }

    .info-item {
        max-width: 100%;
        padding: 20px;
        flex-direction: column;
    }

    .info-header {
        font-size: 18px;
    }

    .info-descr {
        margin-bottom: 16px;
        font-size: 24px;
    }

    .info-sub-descr {
        margin-bottom: 16px;
        font-size: 18px;
    }

    .info-img {
        margin: 0;
    }

    .info-wrapper {
        flex-direction: column;
    }

    .projects-block {
        padding: 32px 0;
    }

    .project-block {
        padding: 20px;
    }

    .projects-header {
        font-size: 32px;
    }

    .contact-wrapper {
        padding: 32px 0;
    }

    .footer {
        padding: 32px 0 !important;
    }
}

@media (max-width: 980px) {
    .buttons-block {
        width: 65%;
    }

    .hero-black {
        font-size: 90px;
    }

    .hero-descr {
        font-size: 22px;
    }
}

@media (max-width: 908px) {
    .hero-black {
        font-size: 80px;
    }

    .hero-descr {
        margin: 0 0 32px 0;
        font-size: 20px;
    }

    .hero-title-block {
        margin: 72px 0 32px;
    }

    .buttons-block {width: 60%;}

    .cookie-wrap-hidden {
        right: -540px;
    }

    .contact-block {
        flex-direction: column;
    }

    .contact-card-wrapper {
        margin-bottom: 20px;
    }

    .contact-card-header {
        font-size: 28px;
    }

    .contact-card-descr {
        font-size: 18px;
    }

    .cookie-wrap {
        right: 0;
        left: 0;
        margin: auto;
        width: 52%;
    }

    .cookie-wrap-hidden {
        right: -1500px;
    }
}

@media only screen and (max-width: 890px) and (orientation: landscape) {
    .buttons-block{
        justify-content: center;
    }
}

@media (max-width: 890px) {
    .buttons-block {
        margin-left: 0;
    }
}

@media (max-width: 825px) {
    .language-selector {
        margin-right: 2rem;
    }
}

@media (max-width: 772px) {
    .hero-black {
        font-size: 70px;
    }

    .language-selector, .language-selector a {
        font-size: 1rem;
    }

    .project-descr {
        font-size: 16px;
    }

    .project-tag {
        font-size: 16px;
    }

    .cookie-wrap {
        width: 65%;
    }
}

@media (max-width: 744px) {
    .logo {width: 169px;}

    .language-selector {
        margin-right: 1rem;
    }

    .project-wrap {
        flex-direction: column;
    }

    .project-header {
        margin-bottom: 16px;
    }

    .project-tag-block {
        margin: 0 0 12px 0;
    }

    .project-sub-wrap {
        margin: 24px 0;
    }

    .project-sub-descr {
        flex-direction: column;
        flex-wrap: nowrap;
    }

    .feature-block {
        margin-right: 0;
    }
}

@media (max-width: 693px) {
    .hero-black {
        font-size: 60px;
    }
}

@media (max-width: 675px) {
    .buttons-block {
        width: 80%;
        justify-content: end;
        margin-top: 0;
    }
}

@media (max-width: 650px) {
    .projects-header {
        padding-left: 0.8rem;
        padding-right: 0.8rem;
    }
}

@media (max-width: 630px) {
    .language-selector {
        margin-right: 1rem;
        margin-left: 1rem;
    }
}

@media (max-width: 579px) {
    .hero-black {
        font-size: 52px;
    }

    .highlight {
        display: inline;
        margin-right: 17px;
    }

    .language-selector:first-child {
        margin-right: 0.5rem;
    }

    .language-selector:last-child {
        margin-left: 0.5rem;
        margin-right: 0.6rem;
    }

    .cookie-wrap {
        width: 95%;
    }

    .cookie_popup {
        justify-content: center;
    }
}

@media (max-width: 545px) {
    .logo {width: 135px;}

    .info-contact {
        flex-direction: column;
    }

    .info-contact-descr {
        margin-bottom: 16px;
    }

    .info-contact-btn {
        margin-top: 16px;
        width: 100%;
    }

    .info-contact-btn button{
        width: 100%;
    }
}

@media (max-width: 533px) {
    .language-selector, .language-selector a {
        font-size: 0.95rem;
    }
}

@media (max-width: 483px) {
    .logo {
        display: none;
    }

    .projects-header {
        padding-left: 0.6rem;
        padding-right: 0.6rem;
    }


    .buttons-block {
        width: 40%;
    }
}

@media (max-width: 433px) {
    .logo {width: 137px;}
}

@media (max-width: 396px) {
    .hero-title-wrapper {
        margin-right: 12px;
    }

    .highlight {
        margin-right: 12px;
    }

    .buttons-block {
        margin-left: 0;
    }
}

@media (max-width: 360px) {
    .logo {width: 118px;}

    .hero-title {font-size: 1.6rem;}
}
