.cookie-card {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    padding: 32px;
    border-radius: 24px;
    background-color: #F4F5F7;
    align-items: flex-start;
}

.cookie-card-light {
    background: rgba(255, 255, 255, 0.08);
}

.cookie-card:last-of-type {
    margin-bottom: 0;
}

.cookie-card-header {
    margin-bottom: 24px;
    font-family: 'Afacad', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    font-feature-settings: 'ss02' on, 'liga' off;
    color: #1B1C1D;
}

.cookie-card-header-light {
    color: #FFF;
}

.cookie-card-descr {
    font-family: 'Afacad', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    font-feature-settings: 'ss02' on, 'liga' off;
    color: #1B1C1D;
}

.cookie-card-descr-light {
    color: rgba(255, 255, 255, 0.70);
}

.cookie-card-sub-descr {
    margin-top: 20px;
}

.cookie-card-list {
    margin-left: 25px;
}

@media (max-width: 1600px) {

}

@media (max-width: 1170px) {

}

@media (max-width: 1024px) {

}

@media (max-width: 570px) {

}

@media (max-width: 360px) {

}
