.button {
    background-color: #FFFFFF;
    border-radius: 24px;
    padding: 10px 24px;
    font-family: "Afacad", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    border: 1px solid #FFFFFF;
    z-index: 1;
    cursor: pointer;
    gap: 6px;
    transition: background-color .1s linear, color .1s linear;
}

.black-background {
    background-color: #1B1C1D!important;
    color: #FFFFFF !important;
    transition: background-color .1s linear, color .1s linear;
}

.button:hover {
    background-color: #388EFF !important;
    color: #FFFFFF !important;
    border: 1px solid #388EFF !important;;
    transition: background-color .1s linear, color .1s linear;
}

@media (max-width: 908px) {
    .button {
        margin-left: 1rem;
        padding: 12px 45px;
        width: 190px;
    }
}

@media (max-width: 744px) {
    .button {
        margin-left: 2rem;
    }
}

@media (max-width: 690px) {
    .button {
        width: 187px;
        margin-left: 1.6rem;
    }
}

@media (max-width: 675px) {
    .button {
        margin-left: 1rem;
        width: 160px;
        padding: 12px 30px;
    }
}

@media (max-width: 600px) {
    .button {
        width: 147px;
        padding: 12px 25px;
    }
}

@media (max-width: 557px) {
    .button {
        margin-left: 0.2rem;
    }
}

@media (max-width: 505px) {
    .button {
        width: 137px;
        padding: 12px 20px;
    }
}

@media (max-width: 490px) {
    .button {
        width: 130px;
        padding: 12px 15px;
    }

}
@media (max-width: 483px) {
    .button {
        width: 170px;
        padding: 12px 30px;
    }
}

@media (max-width: 390px) {
    .button {
        padding: 12px 20px;
    }
}

@media (max-width: 360px) {
    .button {
        padding: 10px 15px;
        width: 120px;
    }
}
