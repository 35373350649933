.form-item-wrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
}

.form-item-block {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

/*checkbox*/

.custom-checkbox {
    display: inline-block;
    position: relative;
    cursor: pointer;
    font-size: 22px;
}

.custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    bottom: -4px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: inherit;
    border: 1.5px solid #C4CCD3;
    border-radius: 6px;
    transition: border .2s linear, background-color .2s linear;
}

.custom-checkbox input:checked ~ .checkmark {
    background-color: #388EFF;
    border: 1.5px solid #388EFF;
    transition: border .2s linear, background-color .2s linear;
}

.custom-checkbox input:hover ~ .checkmark {
    border: 1.5px solid #388EFF;
    transition: border .2s linear;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.custom-checkbox input:checked ~ .checkmark:after {
    display: block;
}

.custom-checkbox .checkmark:after {
    left: 6px;
    top: 2px;
    width: 3px;
    height: 9px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

/*checkbox*/

.form-item-header {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    font-family: 'Afacad', serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-feature-settings: 'ss02' on, 'liga' off;
    color: #1B1C1D;
}

.form-item-header-white {
    color: #FFF;
}

.form-item-input {
    width: 100%;
    padding: 10px 14px;
    font-family: 'Afacad', serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    outline: none;
    border: 1px solid #F4F6F9;
    border-radius: 12px;
    background: #F4F6F9;
    color: #1B1C1D;
    font-feature-settings: 'ss02' on, 'liga' off;
    resize: none;
    transition: border 0.2s linear;
}

.form-item-input:hover {
    border: 1px solid #388EFF;
    transition: border 0.2s linear;
}

.form-item-input:focus {
    border: 1px solid #388EFF;
}

.form-item-input::placeholder {
    color: #7C8C9D;
}

.form-item-input-black {
    color: #FFF;
    border: 1px solid rgba(244, 245, 247, 0.15);
    background: rgba(244, 245, 247, 0.15);
}

.form-item-input-black::placeholder {
    color: #D4DBE280;
}

.form-item-checkbox-descr {
    width: 60%;
    margin-left: 30px;
}

.form-item-btn {
    width: 100%;
    padding: 10px 24px;
    font-family: 'Afacad', serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    cursor: pointer;
    color: #FFF;
    border-radius: 24px;
    background: #1B1C1D;
    border: solid 1px #1B1C1D;
    font-feature-settings: 'ss02' on, 'liga' off;
    transition: color .2s linear,
                background .2s linear,
                border .2s linear;
}

.form-item-btn:hover {
    color: #FFF;
    background: #388EFF;
    border: solid 1px #388EFF;
    transition: color .2s linear,
                background .2s linear,
                border .2s linear;
}

.form-item-btn:disabled {
    color: #FFF;
    background:#C4CCD3;
    border: solid 1px #C4CCD3;
    cursor: not-allowed;
    transition: color .2s linear,
    background .2s linear,
    border .2s linear;
}

.form-item-btn-white {
    color: #1B1C1D;
    background: #FFF;
    border: solid 1px #FFF;
    transition: color .2s linear,
    background .2s linear,
    border .2s linear;
}
