.info-item {
    display: flex;
    max-width: 448px;
    width: 100%;
    margin-right: 20px;
    margin-bottom: 20px;
    padding: 32px;
    border-radius: 24px;
    background: #F4F6F9;
}

.info-item-black {
    background: rgba(255, 255, 255, 0.08);
}

.info-item:last-child {
    margin-right: 0;
}

.info-header {
    margin-bottom: 16px;
    font-family: 'Afacad', serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: #7C8C9D;
    font-feature-settings: 'ss02' on, 'liga' off;
}

.info-descr {
    margin-bottom: revert;
    font-family: 'Afacad', serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    color: #1B1C1D;
    font-feature-settings: 'ss02' on, 'liga' off;
}

.info-descr-black {
    color: #FFF;
}


.info-sub-descr {
    padding: 12px 16px;
    font-family: 'Afacad', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: #166EE3;
    border-radius: 12px;
    background: rgba(56, 142, 255, 0.10);
    font-feature-settings: 'ss02' on, 'liga' off;
}

.info-sub-descr-black {
    color: #388EFF;
}

.info-img {
    margin-left: 32px;
}
