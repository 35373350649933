.cookie-wrapper {
    background-color: #000000;
}

.cookie-wrapper-light {
    background-color: #FFF;
}

.cookie-card-block {
    padding-top: 52px;
    padding-bottom: 52px;
}

.cookie-header {
    margin-bottom: 24px;
    font-family: 'Afacad', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    font-feature-settings: 'ss02' on, 'liga' off;
    color: #1B1C1D;
}

.cookie-header-light {
    color: #FFF;
}

.cookie-update {
    display: inline-block;
    margin-bottom: 24px;
    padding: 8px 16px;
    border-radius: 24px;
    font-family: 'Afacad', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    font-feature-settings: 'ss02' on, 'liga' off;
    color: #388EFF;
    border: 1px solid rgba(56, 142, 255, 0.50);
    background: rgba(56, 142, 255, 0.05);
}

@media (max-width: 1024px) {
    .cookie-card-block {
        padding-top: 32px;
        padding-bottom: 32px;
    }
}

@media (max-width: 744px) {
    .cookie-header {
        margin-bottom: 20px;
    }

    .cookie-update {
        margin-bottom: 20px;
    }

    .cookie-card {
        padding: 16px;
    }
}

@media (max-width: 550px) {

}

@media (max-width: 433px) {

}

@media (max-width: 360px) {

}
