.footer {
    background: #1B1C1D;
    padding: 102px 0;
}

.footer-wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-family: "Afacad", sans-serif;
    font-size: 21px;
    box-sizing: border-box;
    overflow: hidden;
}

.footer-logo-wrap {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex-basis: 38%;
}

.footer-logo {
    cursor: pointer;
    width: 100%;
    height: 32px;
    margin-bottom: 85px;
}

.footer-logo-descr {
    font-family: "Afacad", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    font-feature-settings: 'ss02' on, 'liga' off;
    color: rgba(255, 255, 255, 0.30);
}

.footer-clients-wrap {
    margin-left: 24px;
}

.footer-contacts-wrap {
    display: flex;
    flex-basis: 24%;
    margin-left: 25px;
}

.footer-wrap a {
    text-decoration: none;
    color: #FFFFFF;
}

.footer-contacts {
    color: #FFFFFF;
}

.footer-title {
    margin-bottom: 12px;
    font-family: "Afacad", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    font-feature-settings: 'ss02' on, 'liga' off;
    color: #FFF;
}

.footer-descr,
.footer-descr a {
    font-family: "Afacad", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    font-feature-settings: 'ss02' on, 'liga' off;
    color: rgba(255, 255, 255, 0.80);
}

.footer-client {
    margin-bottom: 8px;
    cursor: pointer;
}

.footer-client:hover {
    color: rgba(255, 255, 255, 0.94);
    transition: color .2s linear;
}

.cookie a:hover,
.phone a:hover {
    border-bottom: 1px solid #FFFFFF;
}

@media (max-width: 1024px) {
    .footer-wrap {font-size: 1.12rem;}
}

@media (max-width: 744px) {
    .footer-logo {
        width: 6.87rem;
        margin-bottom: 37px;
    }
}

@media (max-width: 545px) {
    .footer-wrap {
        flex-direction: column;
    }

    .footer-logo-wrap {
        order: 1;
    }

    .footer-clients-wrap {
        margin: 0 0 32px 0;
    }

    .footer-contacts-wrap {
        margin: 0 0 52px 0;
    }
}

@media (max-width: 433px) {
    .footer-contacts-wrap {
        width: 100%;
        justify-content: space-between;
    }
}
