* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.content, .top-block {
    max-width: 1512px;
    margin: 0 auto;
    padding: 0 64px;
}

.active {
    opacity: 0;
    animation: activeAnimate 1s forwards;
}

.no-scroll {
    overflow: hidden;
}

@keyframes activeAnimate {
    0% {opacity: 0;}
    100% {opacity: 1;}
}
