.feature-block {
    display: flex;
    flex-direction: column;
    flex-basis: 49%;
    margin-bottom: 8px !important;
    margin-right: 8px;
    padding: 16px;
    border-radius: 12px;
    background: #F4F6F9;
}

.feature-block-dark {
    background: rgba(255, 255, 255, 0.05);
}

.feature-block:nth-child(2n) {
    margin-right: 0;
}

.feature-header {
    margin-bottom: 8px;
    font-family: 'Afacad', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    font-feature-settings: 'ss02' on, 'liga' off;
    color: #1B1C1D;
}

.feature-header-dark {
    color: #FFF;
}

.feature-descr {
    font-family: 'Afacad', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    font-feature-settings: 'ss02' on, 'liga' off;
    color: #47525D;
}

.feature-descr-dark {
    color: #FFFFFFB2;
}
