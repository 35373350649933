.project-block {
    display: flex;
    margin-bottom: 20px;
    flex-direction: column;
    padding: 32px;
    border-radius: 24px;
    background: #FFF;
    box-shadow: 0 9px 50px 0 rgba(124, 140, 157, 0.15);
}

.project-block-dark {
    background: rgba(255, 255, 255, 0.08);
    box-shadow: none;
}

.project-wrap {
    display: flex;
}

.project-block:last-of-type {
    margin: 0;
}

.project-header-block {
    display: flex;
    flex-direction: column;
    flex-basis: 49%;
}

.project-header {
    margin-bottom: auto;
    font-family: 'Afacad', sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    font-feature-settings: 'ss02' on, 'liga' off;
    color: #1B1C1D;
}

.project-header-dark {
    color: #FFF;
}

.project-tag-block {
    margin-top: 25px;
}

.project-tag {
    margin-right: 8px;
    padding: 4px 16px;
    font-family: 'Afacad', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    font-feature-settings: 'ss02' on, 'liga' off;
    color: #388EFF;
    border-radius: 24px;
    border: 1px solid rgba(56, 142, 255, 0.50);
    background: rgba(56, 142, 255, 0.05);
}

.project-tag:last-of-type {
    margin: 0;
}

.project-descr {
    flex-basis: 49%;
    font-family: 'Afacad', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    font-feature-settings: 'ss02' on, 'liga' off;
    color: #47525D;
}

.project-descr-dark {
    color: rgba(255, 255, 255, 0.70);
}

.project-sub-wrap {
    margin: 52px 0;
}

.project-sub-head {
    margin-bottom: 12px;
    font-family: 'Afacad', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-feature-settings: 'ss02' on, 'liga' off;
    color: #1B1C1D;
}


.project-sub-head-dark {
    color: #FFF;
}

.project-sub-descr {
    display: flex;
    flex-wrap: wrap;
}
