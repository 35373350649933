.cookie_popup {
    display: flex;
    align-items: center;
}

.cookie_descr {
    max-width: 187px;
    font-family: 'Afacad', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    font-feature-settings: 'ss02' on, 'liga' off;
    color: #47525D;
}

.cookie_link {
    text-decoration: underline;
    color: #388EFF;
    cursor: pointer;
    transition: color .2s linear;
}

.cookie_link:hover {
    color: #166EE3;
    transition: color .2s linear;
}


.mr-20px {
    margin-right: 20px;
}
